import '../../utils/viewport-dimensions';
import '../../../../css/redesign/bundles/r-home/index.scss';
import '../../../../css/redesign/components/skeleton-map-with-sidebar.scss';

import '../../../../svg-icons/r-arrow.svg';
import '../../../../svg-icons/slider-arr-left.svg';
import '../../../../svg-icons/slider-arr-right.svg';
import '../../../../svg-icons/r-calendar.svg';
import '../../../../svg-icons/r-calendar-check.svg';
import '../../../../svg-icons/r-info.svg';
import '../../../../svg-icons/r-locator-map-geo.svg';
import '../../../../svg-icons/r-locator-map-minus.svg';
import '../../../../svg-icons/r-locator-map-plus.svg';
import '../../../../svg-icons/r-locator-percent.svg';
import '../../../../svg-icons/r-locator-t30.svg';
import '../../../../svg-icons/r-personal.svg';
import '../../../../svg-icons/r-pin.svg';
import '../../../../svg-icons/r-search.svg';
import '../../../../svg-icons/r-favorites.svg';
import '../../../../svg-icons/r-favorites-contour.svg';
import '../../../../svg-icons/rating-star.svg';

import '../../../../svg-icons/r-logo-privilege-club.svg';
import '../../../../svg-icons/r-discount-coupon.svg';
import '../../../../svg-icons/r-present.svg';
import '../../../../svg-icons/r-wallet2.svg';
import '../../../../svg-icons/r-calendar-ok.svg';

// Main Banners Observer
const mainSlider = document.querySelector('[data-r-main-slider]');
if (mainSlider) {
  const mainSliderObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return;
      }

      mainSliderObserver.disconnect();

      import(
        /* webpackChunkName: "slick-carousel" */
        'slick-carousel'
      ).then(() => {
        // eslint-disable-next-line no-unused-expressions
        import(/* webpackChunkName: "main-slider" */ './main-slider');
        import(/* webpackChunkName: "gallery-image-product" */ '../../components/partials/GalleryImageProductUI');
      });
    });
  });
  mainSliderObserver.observe(mainSlider);
}

// Main List products Observer
const mainSliderListProducts = document.querySelector('[data-slider-list-products-main]');
if (mainSliderListProducts) {
  const mainListProductsObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return;
      }

      mainListProductsObserver.disconnect();

      import(
        /* webpackChunkName: "slick-carousel" */
        'slick-carousel'
      ).then(() => {
        // eslint-disable-next-line no-unused-expressions
        import(/* webpackChunkName: "main-tabs-list-products" */ './main-tabs-list-products');
        // eslint-disable-next-line no-unused-expressions
        import(/* webpackChunkName: "slider-products" */ './slider-products');
      });
    });
  });

  mainListProductsObserver.observe(mainSliderListProducts);
}

// Answers blog Observer
const questionsForAnswersBlogSlider = document.querySelector('[data-questions-for-answers-blog]');
if (questionsForAnswersBlogSlider) {
  const questionsForAnswersBlogObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return;
      }

      questionsForAnswersBlogObserver.disconnect();

      import(
        /* webpackChunkName: "slick-carousel" */
        'slick-carousel'
      ).then(() => {
        // eslint-disable-next-line no-unused-expressions
        import(/* webpackChunkName: "questions-for-answers-blog" */ './questions-for-answers-blog');
      });
    });
  });
  questionsForAnswersBlogObserver.observe(questionsForAnswersBlogSlider);
}

window.addEventListener('load', () => {
  import(/* webpackChunkName: "main-map-stores" */ './HomeMapChooseOpticsStores/index');
});
